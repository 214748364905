import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import './Footer.css'; // Make sure this path is correct for your project structure

const Footer = () => {
    const location = useLocation(); // Use useLocation to access the current path
    const currentPath = location.pathname;

    return (
        <footer className="footer">
            <p>
                {
                    currentPath === '/privacy-policy' ?
                        <RouterLink to="/" className="footer-link">
                            Go to Home
                        </RouterLink> : // If on /privacy-policy, show link to Home
                        <RouterLink to="/privacy-policy" className="footer-link">
                            Privacy Policy
                        </RouterLink> // Otherwise, show link to Privacy Policy
                }
            </p>
            {/* You can add more footer content here */}
        </footer>
    );
};

export default Footer;