import React, { useState, useEffect } from "react";
import "./ReadBeforeUsing.css";

const ReadBeforeUsing = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isCarouselVisible, setIsCarouselVisible] = useState(false); // State to manage carousel visibility

  const instructions = [
    {
      img: "headers.png",
      description:
        '1. The Excel file must contain "SKU", "Name", and "Image" in the headers.',
    },
    // {
    //     img: 'image-path-2.jpg',
    //     description: 'Abbreviations should be written in full. Eg: "prfrmnc" should be replaced by "performance".'
    // },
    {
      img: "gmail.png",
      description:
        "2. After images are added, the final document will be sent to your input email inbox.",
    },
    {
      img: "spam.png",
      description:
        "3. Check spam inbox if result is not found. Label as 'Not spam' to avoid future occurrences.",
    },
    {
      img: "download_xlsx.png",
      description:
        "4. Done! Please download the .xlsx file to view the images.",
    },
    // Add as many slides as you have for instructions
  ];

  useEffect(() => {
    // Prevent scrolling when the carousel is open
    if (isCarouselVisible) {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      document.body.style.overflow = "hidden";
      return () => (document.body.style.overflow = originalStyle);
    }
  }, [isCarouselVisible]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % instructions.length); // Loop back to start
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + instructions.length) % instructions.length,
    ); // Loop back to end
  };

  const toggleCarouselVisibility = () => {
    console.log("setting visibility to", !isCarouselVisible);
    setIsCarouselVisible(!isCarouselVisible); // Toggle the visibility
  };

  // Early return if not open, no need to render the modal
  if (!isCarouselVisible) {
    return (
      <button onClick={toggleCarouselVisibility}>Read Before Using</button>
    );
  }

  return (
    <div className="modal-backdrop" onClick={toggleCarouselVisibility}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={toggleCarouselVisibility}>
          &times;
        </button>
        <div className="carousel-container">
          <div className="carousel-slide">
            <img
              style={{ width: "40vh" }}
              src={instructions[currentSlide].img}
              alt={`Slide ${currentSlide}`}
            />
          </div>
          <div className="carousel-controls">
            <button className="control-button" onClick={prevSlide}>
              &lt;
            </button>
            <div className="carousel-text">
              <p>{instructions[currentSlide].description}</p>
            </div>
            <button className="control-button" onClick={nextSlide}>
              &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadBeforeUsing;
