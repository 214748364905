import React, { useEffect } from "react";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  console.log("Attempting to render LoginPage");
  const navigate = useNavigate();

  useEffect(() => {
    console.log("login page pruh");
    // Function to process the authentication code
    const processAuthCode = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      const queryString = window.location.search;

      // Alternative manual parsing
      if (!code && queryString.includes("code=")) {
        const codeParam = queryString.split("code=")[1];
        const extractedCode = codeParam.split("&")[0]; // In case there are other parameters
        console.log("Extracted code:", extractedCode);
        await exchangeCodeForToken(extractedCode);
      } else if (code) {
        console.log("Code found:", code);
        await exchangeCodeForToken(code);
      } else {
        console.log("No code found, initiating OAuth");
        initiateGoogleOAuth();
      }
    };

    // Check if the code is in the URL immediately
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    console.log("CODE ALALALA");
    if (!code) {
      console.log("Waiting for code...");
      // Wait for a specified time before checking for the code again
      const timeoutId = setTimeout(processAuthCode, 20); // 2000 milliseconds delay

      return () => clearTimeout(timeoutId); // Cleanup timeout on component unmount
    } else {
      processAuthCode(); // If code is already there, process it immediately
    }
  }, [navigate]);

  const exchangeCodeForToken = async (code) => {
    console.log("exhcna ge o fotok");
    const clientId = process.env.REACT_APP_CLIENT_ID; // Ensure this is set in your .env file
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET; // Significant security risk to expose this
    const redirectUri = process.env.REACT_APP_REDIRECT_URL;
    const tokenUrl = "https://oauth2.googleapis.com/token"; // Google's token exchange endpoint

    try {
      const response = await fetch(tokenUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          code: code,
          client_id: clientId,
          client_secret: clientSecret, // Exposing client_secret here is not recommended
          redirect_uri: redirectUri,
          grant_type: "authorization_code",
        }),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const tokenData = await response.json();
      // console.log("Token response data UOUOLOGIN:", tokenData);

      // // Handle the tokenData, e.g., storing in local storage (consider security best practices)
      // localStorage.setItem('accessToken', tokenData.access_token);
      // if (tokenData.refresh_token) {
      //     localStorage.setItem('refreshToken', tokenData.refresh_token);
      // }

      // Redirect to home page or another route after successful login
      navigate("/home");
    } catch (error) {
      console.error("Error exchanging code for token:", error);
      // Additional error handling
    }
  };

  const initiateGoogleOAuth = async () => {
    console.log("initiate google oauth called");
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_REDIRECT_URL;
    const scope = encodeURIComponent("https://www.googleapis.com/auth/drive.file");

    console.log("redirectUri", redirectUri);
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&access_type=offline&prompt=consent`;
    localStorage.setItem("oauthInitiated", "true");
    window.location.href = authUrl;
  };

  return <div>Redirecting to Google for authentication...</div>;
}

export default LoginPage;

// https://docs.google.com/spreadsheets/d/1BJ2qawvWCpRwCl3-5DEADPKCNwJQioYuFy6Eh-xPtMA/edit#gid=1210407029
