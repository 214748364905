import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./HomePage";
import LoginPage from "./LoginPage";
import PrivacyPolicy from "./PrivacyPolicy";

function App() {
  const [tokenExists, setTokenExists] = useState(null);

  useEffect(() => {
    const checkToken = async () => {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await fetch(`${backendUrl}/check_token`);
        const data = await response.json();
        setTokenExists(data.exists); // Assuming API returns { exists: boolean }
      } catch (error) {
        console.error("Error checking token:", error);
        setTokenExists(false);
      }
    };

    checkToken();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
