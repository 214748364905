import React, { useEffect } from 'react';
import './Modal.css'; // Assuming you have a separate CSS file for the modal

function Modal({ isOpen, message, onClose }) {
    useEffect(() => {
        // Prevent scrolling when the modal is open
        const originalStyle = window.getComputedStyle(document.body).overflow;
        document.body.style.overflow = 'hidden';

        // Revert back to the original style when the modal is closed
        return () => document.body.style.overflow = originalStyle;
    }, [isOpen]); // Only re-run the effect if isOpen changes

    // Early return if not open, no need to render the modal
    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-backdrop" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <p>{message}</p>
                {message !== "Verifying file..." && (
                    <button onClick={onClose}>Close</button>
                )}
            </div>
        </div>
    );
}

export default Modal;