import React, { useEffect, useState } from "react";
import "./Tracker.css"; // Assuming your CSS styles are defined here

const Tracker = () => {
  const [threadsStatus, setThreadsStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    let intervalId;

    const fetchThreadsStatus = async () => {
      setIsLoading(true);
      try {
        // Using fetch to make the HTTP request
        const response = await fetch(`${backendUrl}/get_thread_status`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setThreadsStatus(data);
        setError(null); // Reset error state if successful
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchThreadsStatus();
    intervalId = setInterval(fetchThreadsStatus, 15000);

    // Clear the interval when the component is unmounted
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [backendUrl]);

  if (error) return <div>Error: {error.message}</div>;
  if (threadsStatus.length === 0) return null; // If the list is empty, don't render the Tracker

  const renderThreadStatus = (thread, index) => {
    const fileName = thread.file_path.replace("/var/data/uploads/", "");
    const isCurrentRunning = index === 0; // Assuming the first one is currently running
    return (
      <div
        key={index}
        className={`thread-status ${isCurrentRunning ? "current-running" : ""}`}
      >
        <div className="thread-index">{index + 1}</div>
        <div className="file-name">{fileName}</div>
        {isCurrentRunning && <div className="current-indicator spinner"></div>}
      </div>
    );
  };

  return (
    <div className="tracker">
      <h2 className="tracker-heading">Queue</h2>
      <div className="threads-container">
        {threadsStatus.map(renderThreadStatus)}
      </div>
    </div>
  );
};

export default Tracker;
