// Header.js

import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import "./Header.css"; // Make sure to create a corresponding CSS file for styling

function Header() {
  const location = useLocation(); // Use useLocation to access the current path
  const currentPath = location.pathname;

  return (
    <header className="header">
      <div className="logo-container">
        {/* <img src="your-logo-url-here.png" alt="Logo" className="logo" /> */}
        <span>SKU Automation</span>
      </div>
      <div className="header-actions">
        {
          currentPath === "/privacy-policy" ? (
            <RouterLink to="/" className="footer-link">
              Go to Home
            </RouterLink> // If on /privacy-policy, show link to Home
          ) : (
            <RouterLink to="/privacy-policy" className="footer-link">
              Privacy Policy
            </RouterLink>
          ) // Otherwise, show link to Privacy Policy
        }
        {/* <button className="login-button">Log in</button>
                <button className="trial-button">Start free trial</button> */}
      </div>
    </header>
  );
}

export default Header;
