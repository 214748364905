import React, { useState, useEffect } from 'react';
import './DesktopInstructionsBanner.css'; // Create and style the carousel with CSS

const instructionSlides = [
    {
        img: 'headers.png',
        description: '1. The Excel file must contain "SKU", "Name", and "Image" in the headers.'
    },
    {
        img: 'gmail.png',
        description: '2. After images are added, the final document will be sent to your input email inbox.'
    },
    {
        img: 'spam.png',
        description: '3. Check spam inbox if result is not found. Label as "Not spam" to avoid future occurrences.'
    },
    {
        img: 'download_xlsx.png',
        description: '4. Done! Please download the .xlsx file to view the images.'
    }
];

const DesktopInstructionsBanner = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slidesToShow, setSlidesToShow] = useState(getSlidesToShow(window.innerWidth));

    function getSlidesToShow(width) {
        // if (width >= 3000) return 3;
        if (width >= 1300) return 2;
        return 1;
    }

    useEffect(() => {
        // This is a helper function to calculate the width inside useEffect
        function updateSlidesToShow() {
            setSlidesToShow(getSlidesToShow(window.innerWidth));
        }

        // Set the number of slides to show based on the initial window width
        updateSlidesToShow();

        window.addEventListener('resize', updateSlidesToShow);

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('resize', updateSlidesToShow);
    }, []);


    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + slidesToShow) % instructionSlides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - slidesToShow + instructionSlides.length) % instructionSlides.length);
    };

    // Calculate the indices for the slides to show
    const slideIndicesToShow = Array.from({ length: slidesToShow }, (_, i) =>
        (currentSlide + i) % instructionSlides.length
    );

    return (
        <div className="desktop-instructions-wrapper">
            <div className="carousel-header">
                <p>Read Before Using</p>
            </div>
            <div className="desktop-instructions-carousel">
                {slideIndicesToShow.map((index) => (
                    <div className="carousel-slide" key={index}>
                        <p>{instructionSlides[index].description}</p>
                        <img src={instructionSlides[index].img} alt={`Slide ${index}`} />
                    </div>
                ))}
            </div>
            <div className="carousel-controls">
                <button onClick={prevSlide}>&lt;</button>
                <button onClick={nextSlide}>&gt;</button>
            </div>
        </div>
    );
};

export default DesktopInstructionsBanner;