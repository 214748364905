import React, { useEffect, useState } from 'react';
import './EmailModal.css'; // Make sure you have the CSS styles for the email modal

function EmailModal({ isOpen, onClose, onSubmit, defaultValue = "" }) {
    const [inputValue, setInputValue] = useState(defaultValue);
    const [error, setError] = useState(""); // For validation messages

    useEffect(() => {
        if (isOpen) {
            setError(""); // Reset error messages when the modal opens
        }
        // Prevent scrolling
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                document.querySelector(".emailmodal-input")?.focus();
            }, 100);
        }
    }, [isOpen]);

    const isValidEmail = (email) => {
        // Simple email validation regex
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        if (error) setError(""); // Clear error when user starts typing
    };

    const handleCancel = () => {
        setInputValue("");
        onClose();
    };

    const handleSubmit = () => {
        if (inputValue.trim() === "") {
            setError("Email address is required.");
            return;
        }

        if (!isValidEmail(inputValue)) {
            setError("Please enter a valid email address.");
            return;
        }

        onSubmit(inputValue);
        setInputValue("");
        onClose(); // Consider closing the modal after successful submission
    };

    return (
        <div className="emailmodal-backdrop" style={{ display: isOpen ? 'flex' : 'none' }}>
            <div className="emailmodal-content">
                <p>Please enter your email address (final Excel file will be sent here)</p>
                <input
                    type="email" // Changed to type email for better semantic HTML and mobile keyboards
                    value={inputValue}
                    onChange={handleInputChange}
                    className="emailmodal-input"
                />
                {error && <p className="error-message">{error}</p>}
                <button className="submit-button" onClick={handleSubmit}>Submit</button>
                <button className="cancel-button" onClick={handleCancel}>Cancel</button>
            </div>
        </div>
    );
}

export default EmailModal;