import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./XLSXUploader.css";
import Modal from "./Modal";
import EmailModal from "./EmailModal";
import LoginPromptModal from "./LoginPromptModal";

function XLSXUploader({ isAuthenticated }) {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isLoginPromptOpen, setIsLoginPromptOpen] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      console.log("File selected:", file.name);
    }
  };

  const handleFileSubmit = async () => {
    if (!selectedFile) {
      setModalMessage("Please select a file first.");
      setIsModalOpen(true);
      return;
    }

    setModalMessage("Verifying file...");
    setIsModalOpen(true);

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const verifyResponse = await fetch(`${backendUrl}/verify-xlsx`, {
        method: "POST",
        body: formData,
      });

      if (verifyResponse.ok) {
        const verifyResult = await verifyResponse.json();

        if (verifyResult.has_sku_img_and_name) {
          setIsModalOpen(false); // Close verification modal
          setIsEmailModalOpen(true); // Open email modal if verification is successful
        } else {
          // Update modal message if file validation failed
          setModalMessage(verifyResult.message || "File validation failed.");
        }
      } else {
        // Update modal message in case of HTTP errors
        const errorResult = await verifyResponse.json();
        setModalMessage(
          errorResult.detail || "Error during file verification."
        );
      }
    } catch (error) {
      console.error("Error during file verification:", error);
      setModalMessage("An unexpected error occurred during file verification.");
    }
  };

  const handleEmailSubmit = async (email) => {
    setIsEmailModalOpen(false);

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("email", email);

    try {
      const response = await fetch(`${backendUrl}/upload-xlsx`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const result = await response.json();
        setModalMessage("File uploaded successfully: " + result.message);
        setIsModalOpen(true);
        setSelectedFile(null); // Reset the selected file after successful upload
      } else {
        const errorResult = await response.json();
        setModalMessage(errorResult.detail || "Failed to upload file.");
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      setModalMessage("An unexpected error occurred during file upload.");
      setIsModalOpen(true);
    }
  };

  const handleInputClick = (event) => {
    if (!isAuthenticated) {
      event.preventDefault();
      setIsLoginPromptOpen(true);
    }
  };

  const handleCloseLoginPrompt = () => {
    setIsLoginPromptOpen(false);
  };

  const handleRedirectToLogin = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="xlsx-uploader-container">
        <input
          type="file"
          accept=".xlsx"
          onClick={handleInputClick}
          onChange={handleFileChange}
          className="file-input"
        />
        {selectedFile && <p>File selected: {selectedFile.name}</p>}
        <button onClick={handleFileSubmit} disabled={!selectedFile}>
          Upload File
        </button>
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          message={modalMessage}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      {isEmailModalOpen && (
        <EmailModal
          isOpen={isEmailModalOpen}
          onClose={() => setIsEmailModalOpen(false)}
          onSubmit={handleEmailSubmit}
        />
      )}
      {isLoginPromptOpen && (
        <LoginPromptModal
          isOpen={isLoginPromptOpen}
          onClose={handleCloseLoginPrompt}
          onRedirect={handleRedirectToLogin}
        />
      )}
    </>
  );
}

export default XLSXUploader;
