import React from "react";
import "./LoginPromptModal.css";

const LoginPromptModal = ({ isOpen, onClose, onRedirect }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Please log in with Google to continue...</h2>
                <div className="modal-actions">
                    <button onClick={onClose} className="modal-button cancel">Cancel</button>
                    <button onClick={onRedirect} className="modal-button redirect">Redirect me to login Page</button>
                </div>
            </div>
        </div>
    );
};

export default LoginPromptModal;
