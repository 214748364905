import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Modal from "./Modal";
import Tracker from "./Tracker";
import XLSXUploader from "./XLSXUploader";
import StackedCarousel from "./StackedCarousel";
import "./HomePage.css";
import ReadBeforeUsing from "./ReadBeforeUsing";
import DesktopInstructionsBanner from "./DesktopInstructionsBanner";

function HomePage() {
  const navigate = useNavigate();
  const [sheetLink, setSheetLink] = useState("");
  const [processing, setProcessing] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loadingSheetNames, setLoadingSheetNames] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const imagePaths = ["sheets-example.png", "shipping-wound-care-supplies.jpg"];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const getCode = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      return code;
    };

    const handleAuthentication = async () => {
      const code = getCode();
      if (code) {
        await exchangeCodeForToken(code);
        setIsAuthenticated(true);
      }
    };

    handleAuthentication();
  }, []);

  const exchangeCodeForToken = async (code) => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
    const redirectUri = process.env.REACT_APP_REDIRECT_URL;
    const tokenUrl = "https://oauth2.googleapis.com/token";

    try {
      const response = await fetch(tokenUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          code: code,
          client_id: clientId,
          client_secret: clientSecret,
          redirect_uri: redirectUri,
          grant_type: "authorization_code",
        }),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const tokenData = await response.json();
      await storeToken(tokenData);
    } catch (error) {
      console.error("Error exchanging code for token:", error);
    }
  };

  const storeToken = async (tokenData) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const endpoint = `${backendUrl}/store_token`;

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_token: tokenData.access_token,
          expires_in: tokenData.expires_in,
          refresh_token: tokenData.refresh_token,
          scope: tokenData.scope,
          token_type: tokenData.token_type,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData === "Error. Must force relogin.") {
        navigate("/login");
      } else {
        setShowSuccessScreen(true);
      }
    } catch (error) {
      console.error("Error storing token:", error);
    }
  };

  const fetchSheetNames = async (spreadsheetId) => {
    setLoadingSheetNames(true);
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(
        `${backendUrl}/get_spreadsheet_pages/${encodeURIComponent(spreadsheetId)}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      let res = null;

      if (Array.isArray(data.sheet_names) && data.sheet_names.length > 0) {
        res = data.sheet_names[0];
        localStorage.setItem("rangeName", res);
      }
      return res;
    } catch (error) {
      console.error("Error fetching sheet names:", error);
      throw error;
    } finally {
      setLoadingSheetNames(false);
    }
  };

  const verifySheets = async (sheetUrl, sheetNameToUse) => {
    try {
      const rangeName = sheetNameToUse;
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const endpoint = `${backendUrl}/verify_valid_sheet`;

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          goog_sheet_id: encodeURIComponent(extractSheetId(sheetUrl)),
          range_name: rangeName,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData === "Error. Must force relogin.") {
        navigate("/login");
      } else {
        setShowSuccessScreen(true);
      }
    } catch (error) {
      console.error("Error verifying sheet:", error);
    }
  };

  const startThread = async (sheetUrl, sheetNameToUse) => {
    try {
      const rangeName = sheetNameToUse || localStorage.getItem("rangeName");
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const endpoint = `${backendUrl}/start_thread`;

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          num_of_threads: 1,
          goog_sheet_id: encodeURIComponent(extractSheetId(sheetUrl)),
          goog_sheet_gid: encodeURIComponent(extractGid(sheetUrl)),
          range_name: rangeName,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData === "Error. Must force relogin.") {
        navigate("/login");
      } else {
        setShowSuccessScreen(true);
      }
    } catch (error) {
      console.error("Error starting thread:", error);
    }
  };

  const extractSheetId = (url) => {
    const match = url.match(/\/d\/(.+?)\//);
    return match ? match[1] : null;
  };

  const extractGid = (url) => {
    const urlObj = new URL(url);
    const hash = urlObj.hash;
    const gidMatch = hash.match(/gid=(\d+)/);
    return gidMatch ? gidMatch[1] : null;
  };

  const handleSubmit = async () => {
    if (!sheetLink) {
      console.log("Sheet link is required.");
      return;
    }

    const isValidGoogleSheetsUrl = (url) => {
      const pattern = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/[a-zA-Z0-9-_]+\/?.*$/;
      return pattern.test(url);
    };

    if (!isValidGoogleSheetsUrl(sheetLink)) {
      alert("Please enter a valid Google Sheets URL.");
      return;
    }

    if (!sheetLink.includes("#gid=")) {
      alert("The Google Sheets URL must include the part with '#gid=' at the end.");
      return;
    }

    sessionStorage.setItem("savedLink", sheetLink);

    const checkToken = async () => {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await fetch(`${backendUrl}/check_token`);
        const data = await response.json();
        return data.exists;
      } catch (error) {
        console.error("Error checking token:", error);
        return false;
      }
    };

    const tokenExists = await checkToken();
    if (!tokenExists) {
      navigate("/login");
      return;
    }

    setProcessing(true);
    const sheetNameToUse = await fetchSheetNames(extractSheetId(sheetLink));
    try {
      await verifySheets(sheetLink, sheetNameToUse);
      await startThread(sheetLink, sheetNameToUse);
    } catch (error) {
      console.error("Error in processing sheet:", error);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    const savedLink = sessionStorage.getItem("savedLink");
    if (savedLink) {
      setSheetLink(savedLink);
      sessionStorage.removeItem("savedLink");
    }
  }, []);

  return (
    <div className="gradient-background">
      <Header />
      <div className="split-container">
        <div className="upper-row">
          <div className="left-side content">
            <h3 className="main-heading">SKU Image Search</h3>
            <p className="subtext">
              Get the correct images for your products with just one click.
            </p>
            <h1>Upload an Excel (.xlsx) File</h1>
            <XLSXUploader isAuthenticated={isAuthenticated} />
            <div className="container-for-tracker-and-read-before">
              <Tracker />
              <div className="read-before-using" style={{ paddingTop: "10px" }}>
                <ReadBeforeUsing />
              </div>
            </div>
          </div>
          <div className="img-container">
            <img
              style={{
                maxWidth: "35vw",
                maxHeight: "400px",
                objectFit: "contain",
              }}
              src="Untitled design.png"
              alt="Featured product"
            />
          </div>
          <div className="img-container-mobile">
            <img
              style={{ maxWidth: "75vw", objectFit: "contain" }}
              src="Untitled design.png"
              alt="Featured product"
            />
          </div>
        </div>
        <div className="lower-row">
          <div className="desktop-instructions-banner" style={{ paddingBottom: "10px" }}>
            <DesktopInstructionsBanner />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          message={modalMessage}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      {/* <Footer /> Uncomment or adjust the footer as needed */}
    </div>
  );
}

export default HomePage;
